* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --primary: #fcc016;
  --secondary: #232b38;
  --ternary: #fffefd;
  --danger: #fe4545;
  --pure: #fefefe;
  --light: #f0f0f0;
  --heaven: #ffffff;
  --footer: #2d333f;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.pt-120 {
  padding-top: 120px;
}
.customAvatarImg {
  width: 25%;
}
.card-top {
  background: url(../public/Assets/SuperDog/bg-large.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.pagination ul li a {
  font-family: "Quicksand";
  font-weight: 700;
  padding: 10px;
}
.pagination ul li:hover {
  background: black;
  color: white;
  border-radius: 8px;
}
.pagination ul .previous:hover {
  background: transparent !important;
}
.pagination ul .next:hover {
  background: transparent !important;
}

span.Hero-variations span {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 28.7348px;
  color: white;
}
.custom-countdown {
  display: flex;
  gap: 0px 8px;
}
.card-img {
  overflow: hidden;
}
img.main-image-food-kit {
  max-width: 100%;
}

.nftProduct.food-kit {
  margin-bottom: 150px;
}

li.previous a {
  background: url(../public/Assets/SuperDog/left.png);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  color: transparent !important;
}

li.next a {
  background: url(../public/Assets/SuperDog/right.png);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  color: transparent !important;
}

.pagination ul {
  height: 60px;
}

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}

@media (min-width: 767px) {
  .hide-on-desktop {
    display: none;
  }
}

.main-tit-wrap {
  background: url(../public/Images/main-tt.png);
  background-size: 100% 100%;
  padding: 17px 0px;
}

.main-tit-wrap {
  background: url(../public/Images/main-tt.png);
  background-size: 100% 100%;
  padding: 17px 0px;
}

.main-tit {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-size: 22px;
  text-transform: uppercase;
  margin-top: 6px;
  margin-left: 10px;
}

.main-tit2 {
  background: url(../public/Images/main-tt.png);
  background-size: 100% 100%;
  margin: 0 auto;
  text-align: center;
  padding: 21px 0px;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 800;
  width: 263px;
  height: 70px;
  position: relative;
  z-index: 2;
  color: #121f41;
  font-family: "Quicksand";
  font-weight: 700;
  -webkit-text-stroke: 0.5px;
}

.line-after {
  width: 50%;
  height: 2px;
  background: white;
  /* margin-bottom: -45px; */
  margin-top: -46px;
  text-align: right;
  margin-left: auto;
  position: relative;
  z-index: 1;
  margin-bottom: 46px;
}
.line-before {
  width: 50%;
  height: 2px;
  background: white;
  margin-bottom: -45px;
  margin-top: 45px;
}

.Market-product-cards.food-kit-single .card-top .card-img img {
  max-width: 100%;
}

@media (max-width: 480px) {
  img.main-image {
    width: 750px !important;
  }
  .nft-details-img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .nft-name {
    flex-direction: row !important;
    align-items: start !important;
    justify-content: center !important;
    text-align: center !important;
    padding-bottom: 11px !important;
  }

  .NftDetail .nft-details .nft-name {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.nft-details-img {
  background: url(../public/Assets/SuperDog/bg-large.png);
  max-width: 100%;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
}

.Market-product-cards.food-kit-single .card-top {
  background: url(../public/Assets/SuperDog/green-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.nft-details-img-food {
  background: url(../public/Assets/SuperDog/green-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
