.Footer {
  border-top: 0.01px solid var(--primary);
  padding-top: 100px;
  padding-bottom: 150px;
  background: url("../../Assets/Images/Background.png");
}
.Footer .Footer-content .footer-logo {
  display: flex;
  align-items: center;
}

.Footer .Footer-content .learn-more {
  display: flex;
  flex-direction: column;
}
.Footer .Footer-content .footer-market {
  display: flex;
  flex-direction: column;
}
.Footer .Footer-content .quick-links {
  display: flex;
  flex-direction: column;
}
.Footer .Footer-content .subscribe {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Footer-content .footer-head h6 {
  font-family: "Open Sans";
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 16.1664px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--primary);
  width: fit-content;
}
.Footer .Footer-content .learn-more a,
.Footer .Footer-content .footer-market a,
.Footer .Footer-content .quick-links a,
.Footer .Footer-content .subscribe a {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 17.9131px;
  letter-spacing: 1px;
  line-height: 22px;
  color: var(--heaven);
  margin-top: 11px;
  width: fit-content;
  transition: all 0.3s linear;
}

.input-wrap {
  display: flex;
  border: 0.790309px solid var(--heaven);
  border-radius: 11.0643px 12px 12px 10.274px;
  /* width: 70%; */
  margin: 11px 0;
}

.input-wrap input {
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  padding: 12px 16px;
  font-family: "Roboto";
  font-size: 14.2493px;
  font-weight: 300;
  letter-spacing: 0.02em;
  line-height: 17px;
}

.input-wrap button {
  padding: 0.5rem 2rem;
  background: var(--primary);
  color: var(--pure);
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  outline: none;
  border-radius: 0px 11.0643px 11.0643px 0px;
}
.social-icons {
  margin-top: 13px;
  width: 100%;
}
.input-wrap input::placeholder {
}

@media screen and (max-width: 992px) {
  .Footer .Footer-content .subscribe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }
}
@media screen and (max-width: 415px) {
  .Footer .Footer-content .subscribe,
  .Footer .Footer-content .learn-more,
  .Footer .Footer-content .footer-market,
  .Footer .Footer-content .quick-links {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto;
  }
  .Footer .Footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sociol-icons {
  transition: all 0.3s linear;
}
.sociol-icons:hover {
  color: #fac427 !important;
}

.Footer .Footer-content .footer-market a:hover {
  color: #fac427 !important;
}
