.NftDetail .NftDetail-heading {
  display: flex;
  justify-content: center;
  padding: 80px 0;
}
.NftDetail .nftProduct {
  /* background: url("../../Assets/Images/nftDetailPage/nftDetailBack.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid var(--primary);
  border-radius: 10px;
}
/* .NftDetail .nftProduct .nftThumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.col-md-5.col-12.nftThumbail {
  align-items: center;
  display: flex;
}

.NftDetail .nft-details {
  padding-left: 0px !important;
}
.NftDetail .nft-details .nft-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.NftDetail .nft-details .nft-name h1 {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 48.9788px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: var(--primary);
}

.NftDetail .nft-details .quantity {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
  border-radius: 4px;
  background: #29abe3;
  padding: 2px 20px;
  cursor: pointer;
}
.NftDetail .nft-details .buy-nft-button {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: bolder;
  font-size: 22px;
  color: #000000;
  border-radius: 4px;
  background: #fcc016;
  padding: 2px 20px;
  cursor: pointer;
}
.discription {
  border-left: 6px solid var(--primary);
}

.discription .nft-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0px 22px 22px 0px;
  width: 50%;
}
.discription .nft-price img {
  height: 16px;
}

.discription .nft-price h3 {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: var(--heaven);
  margin-bottom: 0px;
  /* margin-right: 10rem; */
}

.discription .price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.discription .price h5 {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 18.98px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-bottom: 0px;
  margin-left: 5px;
}

.nft-discription {
  background: rgba(255, 255, 255, 0.2);
  margin-top: 20px;
}

.nft-discription .nft-discription-head h5 {
  background: linear-gradient(
    90deg,
    rgba(252, 192, 22, 0.2) -3.08%,
    rgba(255, 255, 255, 0) 104.63%
  );
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 18.98px;
  line-height: 25px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #fcc016;
  padding: 4px 10px;
  width: 50%;
  margin-bottom: 0;
}

.nft-discription .nft-discription-para {
  padding: 2px 10px;
}
.nft-discription .nft-discription-para p {
  margin-top: 10px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #ffffff;
}

.discription .nft-discription-detail {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.2);
}
.discription .nft-discription-detail .nft-discription-detail-head h5 {
  background: linear-gradient(
    90deg,
    rgba(252, 192, 22, 0.2) -3.08%,
    rgba(255, 255, 255, 0) 104.63%
  );
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 18.98px;
  line-height: 25px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #fcc016;
  padding: 4px 10px;
  width: 50%;
  margin-bottom: 0;
}
.discription .nft-discription-detail .nft-features .nft-features-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
}

.discription .nft-discription-detail .nft-features .nft-features-head h3 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #fcc016;
  margin-bottom: 0px;
  padding: 4px 10px;
}

/* ----------------------- */

.feature-detail {
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
}

.feature-detail .feature-name h4 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #ffffff;
}
.feature-progress {
  width: 60%;
  position: relative;
}
.myBar {
  /* width: 50%; */
  height: 10px;
  border-radius: 8px;
  background: #d3d0d0;
}
.mybar100 {
  background: #1e1e1e;
  height: 10px;
  border-radius: 8px;
  width: 100%;
}

.feature-progress .prog {
  position: absolute;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #ffffff;
  right: 0;
  bottom: 2px;
}
.dogImg {
  max-width: 1000px;
}
@media screen and (max-width: 415px) {
  .NftDetail .nftProduct .nftThumbnail {
    margin-bottom: 2rem;
  }
  .discription {
    margin-top: 2rem;
  }
  .discription .nft-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0px 22px 22px 0px;
    width: 80%;
  }
  .discription .nft-discription-detail .nft-features .nft-features-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }
  .feature-detail {
    padding: 4px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }
}

@media screen and (max-width: 361px) {
  .discription .nft-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0px 22px 22px 0px;
    width: 90%;
  }
}
.loader {
  bottom: 45%;
  position: fixed;
  right: 47%;
  /* left: 50%; */

  /* border-radius: 50%; */
  width: 80px;
  height: 80px;
  z-index: 999;
}
/* img.main-image {
  width: 950px;
} */
img.main-image {
  width: 760px;
}

.wrapp-outer {
  display: flex;
  height: 50px !important;
  /* justify-content: end; */
  /* padding-right: 45px; */
}
.approveBuyBtn {
  justify-content: end;
  /* padding: 0%; */
}
.loaderSecond {
  bottom: 50%;
  position: fixed;
  right: 37%;
  /* left: 50%; */
  color: #ffffff;
  /* border-radius: 50%; */
  width: 300px;
  height: 60px;
  z-index: 999;
}
.customLoadingText {
  font-family: "DM Mono";
  font-weight: 900;
  color: white;
  font-size: 25px;
  text-shadow: 0 0 13px #fcc016, 0 0 1px #fcc016;
}
.wrapperMarketDetail {
  display: inline-flex;
  /* background: #fff; */
  height: 80px;
  width: 300px;
  align-items: center;
  /* justify-content: space-evenly; */
  border-radius: 5px;
  padding: 20px 15px;
  /* box-shadow: 5px 5px 30px rgba(0,0,0,0.2); */
}
.wrapperMarketDetail .option {
  /* background: #fff; */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapperMarketDetail .option .dot {
  height: 20px;
  width: 20px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
}
.wrapperMarketDetail .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #fcc016;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
