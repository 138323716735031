.DeflyTopNFT {
  padding-top: 150px;
  padding-bottom: 150px;
}
.DeflyTopNFT .topNft {
  margin-top: 110px;
}

.DeflyTopNFT .DeflyTopNFT-head h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 32.4597px;
  text-transform: uppercase;
  text-align: center;
  color: var(--heaven);
}
.DeflyTopNFT .topNft .topNftProduct {
  background: rgba(48, 56, 77, 0.5);
  backdrop-filter: blur(62px);
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 20px;
}
.DeflyTopNFT .topNft .topNftProduct .nft-img {
  margin-right: 20px;
}
.DeflyTopNFT .topNft .topNftProduct .topnftProductDetail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.DeflyTopNFT .topNft .topNftProduct .topnftProductDetail p {
  margin-bottom: 0 !important;
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 10.3583px;
  color: #ffffff;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
}
.DeflyTopNFT .topNft .topNftProduct .topnftProductDetail a {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 10.3583px;
  line-height: 13px;
  color: #cbc8bd;
}
.DeflyTopNFT .topNft .topNftProduct .topnftProductDetail img {
  height: 13px;
  margin-right: 5px;
}
