.Hero {
  background-image: url("../../Assets/Images/HeroBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.Hero .container {
}
.Hero .Hero-Content .content-top {
  padding: 60px 0 0 0;
}

.Hero .Hero-Content .HeroSlogans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
}
.Hero .Hero-Content .HeroSlogans h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 60.7348px;
  line-height: 76px;
  color: var(--heaven);
  text-shadow: -1px -1px 0 var(--primary), 1px -1px 0 var(--primary),
    -1px 1px 0 var(--primary), 1px 1px 0 var(--primary);
}
.Hero .Hero-Content .HeroSlogans h2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 39.004px;
  line-height: 49px;
  letter-spacing: 0.27em;
  color: var(--primary);
}
.HeroPhotos-set {
  display: flex;
  justify-content: center;
}
.HeroPhotos-set img {
  /* height: 22rem !important; */
}
.Hero .Hero-Content .Hero-variations {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 11.6286px;
  padding: 10px;
  width: 290px;
  margin-bottom: 70px;
  border: 2px solid #fcc016;
}
.Hero .Hero-Content .Hero-variations .art-counter {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 22.1428px;
  letter-spacing: 0.005em;
  color: var(--heaven);
  margin-bottom: 0px;
}

.Hero .Hero-Content .Hero-variations .art-tag {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 17.7143px;
  line-height: 27px;
  letter-spacing: 0.005em;
  color: #fffefd;
  opacity: 0.6;
  margin-bottom: 0px;
}

/* Media Queries */

@media screen and (max-width: 992px) {
  .Hero .Hero-Content .HeroSlogans {
    text-align: center;
  }
  .hero-var {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .Hero .Hero-Content .HeroSlogans h1 {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 52.4px;
    line-height: 76px;
    color: var(--heaven);
    text-shadow: -1px -1px 0 var(--primary), 1px -1px 0 var(--primary),
      -1px 1px 0 var(--primary), 1px 1px 0 var(--primary);
  }
  .Hero .Hero-Content .HeroSlogans h2 {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 29.004px;
    letter-spacing: 0.27em;
    color: var(--primary);
  }
  .nav-logo .logogif img {
    position: absolute;
    top: -44px;
    left: -45px;
    mix-blend-mode: lighten;
    width: 160px;
    height: 160px;
  }
  .nav-logo .logo {
    max-width: 70%;
  }
}
