.navbar {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.nav-logo {
  display: flex;
  align-items: center;
  position: relative;
}

.nav-logo .logogif img {
  position: absolute;
  top: -54px;
  left: -55px;
  mix-blend-mode: lighten;
  width: 214px;
  height: 214px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: var(--heaven);
}
.navbar ul {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
}
.navbar ul li {
  margin-left: 10px;
}
.navbar ul li a:hover {
  color: var(--primary);
}

.navbar-nav .ball-nft {
  /* background: transparent;
  border: 1.90371px solid var(--heaven);
  border-radius: 25px;

  padding: 9px 29px !important; */
}
.navbar .wallet {
  border: 2.23902px solid var(--primary);
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: var(--heaven);
}
.nav-item a {
  color: var(--heaven);
}
.nav-item .upcoming-nav {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #ababab;
  /* color: rgba(255, 255, 255, 0.8); */
}
.nav-item .nav-link:hover {
  color: var(--primary);
}

.nav-item .nav-link:active {
  color: var(--primary);
}
.navbar-toggler {
  border-color: #fff;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.modalBody {
  background: url("../../Assets/Images/connectWalletImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 10px;
  border-radius: 8px;
  margin: 1px;
  border: none;
  /* background-color: #000; */
}

/* ----------------------------------------------------------------- */
.connect-wallet .connect-wallet-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 1.4rem;
}
.modal-content {
  background: black !important;
  border: 1px solid white !important;
}

.connect-wallet .connect-wallet-content h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 3.6rem !important;
  color: var(--heaven) !important;
  text-align: center;
  letter-spacing: 0.07em;
  text-shadow: -1px 1px 0 var(--primary), 1px 1px 0 var(--primary),
    1px -1px 0 var(--primary), -1px -1px 0 var(--primary);
}
.connect-wallet .connect-wallet-content h2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  letter-spacing: 0.07em;
  color: #fcc016;
  margin-top: 1rem;
  text-align: center;
}

.connect-wallet .connect-wallet-content a {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  border: 1px solid var(--primary);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  opacity: 0.7;
  text-shadow: -1px -1px 0 var(--heaven), 1px -1px 0 var(--heaven),
    -1px 1px 0 var(--heaven), 1px 1px 0 var(--heaven);
  transition: all ease 2s;
  margin-top: 0.5rem;
  width: fit-content;
  text-align: center;
}
.connect-wallet .connect-wallet-content img {
  height: 45px;
}

.connect-wallet .connect-wallet-content a:hover {
  background-color: var(--primary);
}

/* ----------------------------------------------------------------- */

@media screen and (max-width: 415px) {
  .wallet {
    padding: 1px 10px;
    margin-bottom: 100px;
  }
  .connect-wallet .connect-wallet-content {
    padding: 2rem 1rem;
  }
  .connect-wallet .connect-wallet-content h1 {
    font-size: 3.2rem !important;
  }
  .connect-wallet .connect-wallet-content h2 {
    font-size: 2.9rem;
  }
  .connect-wallet .connect-wallet-content a {
    font-size: 1.8rem;
  }
}
