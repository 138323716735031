.myNft {
  background: url("../../Assets/Images/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.myNft .NftDetail-heading {
  display: flex;
  justify-content: center;
  padding: 80px 0;
}
