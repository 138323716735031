.Market {
  background: url("../../Assets/Images/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.Market .market-content .market-content-head {
  text-align: center;
}
.marketTabs {
  padding: 120px 0 10px 0;
  overflow-x: auto;
  scrollbar-width: 100%;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.marketTabs::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.marketTabs img {
  width: 170px;
  height: 138px;
}

.market-product-tab.active img {
  transform: scale(1.2);
  /* box-shadow: #ffffff; */
  filter: drop-shadow(0px 0px 28px var(--primary));
}
.market-product-tab.active p {
  color: var(--primary) !important;
}

.marketTabs .market-product-tab .caption {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 19.3645px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-top: 30px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: unset;
}
.tab-pane {
  display: flex;
}
.tab-content {
  display: flex;
  /* justify-content: center; */
}

@media screen and (max-width: 1366px) {
  .marketTabs {
    display: flex;
    /* justify-content: center; */
  }
}
@media screen and (max-width: 1920px) {
  .marketTabs {
    /* display: flex; */
    /* justify-content: center; */
  }
  .tab-content {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 1536px) {
  .marketTabs {
    /* display: flex; */
    /* justify-content: center; */
  }
  .tab-content {
    /* display: flex; */
    /* justify-content: center; */
  }
}
@media screen and (min-width: 1024px) {
  .marketTabs {
    display: flex;
    justify-content: center;
  }
  .tab-content {
    /* display: flex; */
    /* justify-content: center; */
  }
}

@media screen and (max-width: 414px) {
  .Market .market-content .market-content-head {
    text-align: center;

    /* margin: 0 90px; */
    overflow: hide;
  }
}

.pagination ul {
  display: flex;
  background: #fac427;
  /* width: 190px; */
  justify-content: center;
  padding: 0;
  gap: 6px;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 12px;
  padding: 0px 31px;
}

.pagination {
  justify-content: center;
  align-items: center;
}
li.previous a {
  color: black;
  /* color: white; */
  font-size: 26px;
  font-weight: 600;
}
li.next a {
  color: black;
  /* color: white; */
  font-size: 26px;
  font-weight: 600;
}
.pagination ul li {
  border: 1px solid #0000;
}

.pagination ul li.selected {
  background: black;
  color: white;
  border-radius: 8px;
}

@media (max-width:356px) {
  .pagination ul li a {
  
    padding: 10px 5px;
}
}