.Hero .Hero-Content .HeroSlogans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
}
.mystery-box {
  background-image: url("../../Assets/Images/MysteryBoxBack.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.mystery-box .mystery-head h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 60.7348px;
  color: var(--heaven);
  text-shadow: -1px -1px 0 var(--primary), 1px -1px 0 var(--primary),
    -1px 1px 0 var(--primary), 1px 1px 0 var(--primary);
}
.mystery-box p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
  color: #ffffff;
}
.mystry-img button {
  border: none;
  background: #fcc016;
  border-radius: 4.09091px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  color: #000000;
  padding: 0.5rem 1rem;
  width: fit-content;
  float: right;
}

.mystry-left {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.mystry-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* margin-top: 100px; */
}
.mystry-img img {
  height: 50%;
}

@media screen and (max-width: 415px) {
  .mystery-box .mystery-head h1 {
    margin: 3rem 0;
    font-size: 3.2rem;
    text-align: center;
  }
  .mystery-box p {
    text-align: justify;
    padding: 0 1rem;
    margin: 1rem 0;
  }
  .mystry-img button {
    margin-top: 1rem;
  }
  .mystry-left {
    align-items: center;
  }
  .mystery-box {
    padding-bottom: 1000px;
  }
}
@media screen and (max-width: 361px) {
  .mystery-box .mystery-head h1 {
    margin: 3rem 0;
    font-size: 2.2rem;
    text-align: center;
  }
  .mystery-box p {
    text-align: justify;
    padding: 0 1rem;
    margin: 1rem 0;
  }
  .mystry-img button {
    margin-top: 1rem;
  }
}

/* ----------- */
.Hero-variationss {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 11.6286px;
  padding: 10px;
  width: 290px;
}
.Hero-variationss .art-counter {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 22.1428px;
  letter-spacing: 0.005em;
  color: var(--heaven);
  margin-bottom: 0px;
}

.Hero-variationss .art-tag {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 17.7143px;
  line-height: 27px;
  letter-spacing: 0.005em;
  color: #fffefd;
  opacity: 0.6;
  margin-bottom: 0px;
}
.radioSpan {
  border: none;
  background: #fcc016;
  border-radius: 4.09091px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  color: #000000;
  padding: 0.5rem 1rem;
  width: -moz-fit-content;
  width: fit-content;
}
.wrapper {
  display: inline-flex;
  /* background: #fff; */
  height: 80px;
  width: 300px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
  /* box-shadow: 5px 5px 30px rgba(0,0,0,0.2); */
}
.wrapper .option {
  /* background: #fff; */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #fcc016;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3 {
  border-color: #fcc016;
  background: #fcc016;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot,
#option-3:checked:checked ~ .option-3 .dot {
  background: #ffffff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before,
#option-3:checked:checked ~ .option-3 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  color: #000000;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  color: #000000;
}
.mysteryBoxButtonLoader {
  bottom: 38%;
  position: fixed;
  right: 34%;
  /* left: 50%; */
  /* border-radius: 50%; */
  width: 30px;
  height: 30px;
  z-index: 999;
}
.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #fac937;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  background-color: #fac937;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #fac937;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  font-family: "Quicksand";
  color: #ff0000;
}

.show-counter .countdown > p {
  font-family: "Quicksand";
  font-weight: 600;
  color: #000000 !important;
  margin: 0;
}

.show-counter .countdown > span {
  font-family: "Quicksand";
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
