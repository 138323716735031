.Market-product-cards {
  background: url("../../Assets/Images/NFTBackground2.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  /* background-size: cover; */
  /* background-color: #403d3d; */
  border: 1px solid var(--primary);
  border-radius: 15px;
  width: 273px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: space-between;
  position: relative;
}

.Market-product-cards .card-top .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Market-product-cards .card-center {
  padding-top: 10px;
}
.Market-product-cards .favourite {
  position: absolute;
  right: 25px;
  top: 25px;
}
.Market-product-cards .favourite p img {
  height: 16px;
  margin-right: 3px;
}
.Market-product-cards .favourite p {
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 11px;
  text-transform: capitalize;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  padding: 5px 5px;
  background: rgba(52, 52, 68, 0.9);
  border-radius: 4.33358px;
}

.Market-product-cards .card-center .nft-name {
  background: rgba(217, 217, 217, 0.21);
  padding: 11px;
  backdrop-filter: blur(4px);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Market-product-cards .card-center .nft-name a {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 14.4453px;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--heaven);
}

.Market-product-cards .card-bottom {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.Market-product-cards .card-bottom .nft-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Market-product-cards .card-bottom .nft-detail .nft-creator {
  display: flex;
  align-items: center;
}

.Market-product-cards .card-bottom .nft-head {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 10.834px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #817c7c;
}
.Market-product-cards .card-bottom h5 {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 11.5562px;

  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: var(--heaven);
}

.Market-product-cards .card-bottom .nft-detail .nft-price {
  display: flex;
  flex-direction: column;
}
.Market-product-cards .card-bottom .nft-detail .nft-price .price {
  display: flex;
  align-items: center;
}

.Market-product-cards .card-bottom .nft-detail .nft-price .price img {
  margin-top: -7px;
  height: 16px;
  margin-right: 5px;
}
.itemCustomName {
  color: #ffffff !important;
}
