.my-nft-hero {
  background-image: url("../../Assets/Images/nftDetailPage/myNftHeroBack.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.my-nft-hero .my-nft-hero-slogan {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 6rem;
  height: 100%;
}
.my-nft-hero .my-nft-hero-slogan h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 60.7348px;
  line-height: 76px;
  /* identical to box height */

  color: #ffffff;
}
.my-nft-hero .my-nft-hero-slogan h2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 45.5474px;
  line-height: 57px;
  /* identical to box height */

  letter-spacing: 0.27em;

  color: #fcc016;
}
.my-nft-hero .my-nft-hero-slogan h3 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 31.9534px;
  line-height: 40px;
  /* identical to box height */

  letter-spacing: 0.27em;

  color: #fcc016;
}

@media screen and (max-width: 376px) {
  .my-nft-hero .my-nft-hero-slogan h3 {
    font-size: 29px;
  }
}
@media screen and (max-width: 361px) {
  .my-nft-hero .my-nft-hero-slogan h3 {
    font-size: 26px;
  }
}
